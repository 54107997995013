.App-header {
  background-image: url('../public/game-background.png'); /* Update with the correct path */
  background-repeat: repeat;
  background-size: contain;
  color: white;
  text-align: center;
  padding: 2rem 0;
}

.App-header h1 {
  font-size: 4rem; /* Larger font size */
  font-weight: 900; /* Maximum boldness */
  color: white;
  text-shadow:
    9px 9px 0 #000, /* Simulating outline with text shadows */
    -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000;
}

.App-header p {
  font-size: 2rem; /* Larger font size */
  font-weight: 400; /* Maximum boldness */
  color: white;
  text-shadow:
    3px 3px 0 #000, /* Simulating outline with text shadows */
    -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000;
}